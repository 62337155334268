import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"

const Page = props => {
  const {
    location,
    dispatch,
    transitionStatus,
    data: { allWpPage },
  } = props
  const { title, content, slug, background } = allWpPage.edges[0].node

  useEffect(() => {
    // change bg and sticky header colour instantly,
    dispatch(toggleBackground(background.backgroundColour))
    setTimeout(() => {
      dispatch(toggleStickyHeaderColour(background.backgroundColour))
    }, 1000)
  }, [])

  useEffect(() => {
    // update breadcrumbs state
    setTimeout(() => {
      dispatch(
        setBreadcrumbs({
          current: {
            title: title,
            path: slug,
          },
        })
      )
    }, 1000)
  }, [])

  return (
    <SiteLayout location={location} transitionStatus={transitionStatus}>
      <Seo title={`${title}`} />

      <Hero slogan={title} halfHeight transitionStatus={transitionStatus} />

      <main id="main" className="main-content main-content--half">
        <section id="intro" className="section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12 col-bp3-8 col-bp5-6">
                <div
                  className="entry"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(Page)

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          slug
          background {
            backgroundColour
          }
        }
      }
    }
  }
`
